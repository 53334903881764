/* eslint-disable global-require */
import { Button, Layout, Text } from '@ui-kitten/components';
import React, { useContext } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import ThemedSafeAreaView from '../components/ThemedSafeAreaView';
import AuthContext from '../context/AuthContext';
import Styles, { spacing } from '../utils/styling';

const SignInScreen = () => {
  const authContext = useContext(AuthContext);

  return (
    <ThemedSafeAreaView style={styles.container}>
      <Layout style={styles.layout}>
        <View style={styles.logoContainer}>
          <View
            style={styles.logo}
          >
            <Image
              style={styles.image}
              source={require('../assets/redsolocup.png')}
              resizeMethod='scale'
              resizeMode='contain'
            />
            <Text style={styles.title} category='h1'>I Got The Cup</Text>
          </View>
        </View>

        <View
          style={styles.buttonContainer}
        >
          {!authContext.isSignedIn && (
            <Button
              onPress={() => authContext.signIn()}
            >
              Sign in / Sign up
            </Button>
          )}
        </View>

      </Layout>
    </ThemedSafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  logoContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  logo: {
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    width: '100%',
    position: 'absolute',
    top: 100,
    textShadowColor: '#111111',
    textShadowOffset: { width: -1, height: 0 },
    textShadowRadius: 10,
  },
  image: {
    width: '100%',
    height: 300,
  },
  buttonContainer: {
    flex: 1,
    padding: Styles.spacing.horizontal,
    justifyContent: 'center',
  },
  layout: {
    flex: 1,
    paddingVertical: spacing(16),
  },
});

export default SignInScreen;
