import { Divider, Layout, Text, ListItem, TopNavigation, Icon, TopNavigationAction, OverflowMenu, MenuItem } from '@ui-kitten/components';
import dateformat from 'dateformat';
import React, { useContext, useState } from 'react';
import { ImageProps, SectionList, StyleSheet, View } from 'react-native';
import useApiRequest, { Game } from '../api/Api';
import CallToAction from '../components/CallToAction';
import FullSpinner from '../components/FullSpinner';
import { ScreenProps } from '../components/Navigation';
import ThemedSafeAreaView from '../components/ThemedSafeAreaView';
import AuthContext from '../context/AuthContext';
import { useEffectAsync } from '../hooks/useEffectAsync';
import Styles, { spacing } from '../utils/styling';

type HomeScreenProps = ScreenProps<'HomeScreen'>;

const HomeScreen = ({
  navigation,
}: HomeScreenProps) => {
  const authContext = useContext(AuthContext);
  const [menuVisible, setMenuVisible] = useState(false);
  const gamesReq = useApiRequest<Game[]>({ method: 'get', path: 'api/game' });

  useEffectAsync(async () => {
    await gamesReq.makeRequest();
  }, []);

  const finishedGames: Game[] = [];
  const inProgressGames: Game[] = [];
  const futureGames: Game[] = [];

  gamesReq.state.data?.forEach((game) => {
    if (game.isComplete) {
      finishedGames.push(game);
      return;
    }
    if (game.isStarted) {
      inProgressGames.push(game);
      return;
    }
    futureGames.push(game);
  });

  const sections = [{
    title: 'In Progress',
    data: inProgressGames,
  }, {
    title: 'Coming Up',
    data: futureGames,
  }, {
    title: 'Complete',
    data: finishedGames,
  }];

  const renderGame = (game: Game) => {
    let timestamp;
    if (game.isComplete) {
      timestamp = dateformat(game.endTime, 'mmm dS');
    } else if (game.isStarted) {
      timestamp = 'now';
    } else {
      timestamp = dateformat(game.scheduledStartTime, 'mmm dS"," h:MM TT Z');
    }
    return (
      <ListItem
        title={game.name ? game.name : 'Game'}
        description={timestamp}
        onPress={() => navigation.push('GameScreen', { gameId: game.id })}
      />
    );
  };

  const renderSectionHeader = (sectionName: string) => (
    <Text style={styles.sectionHeader} category='h6'>{sectionName}</Text>
  );

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const LogoutIcon = (props?: Partial<ImageProps>) => <Icon {...props} name='log-out' />;

  const SettingsIcon = (props?: Partial<ImageProps>) => (
    <Icon {...props} name='settings-outline' />
  );

  const Settings = () => (
    <OverflowMenu
      anchor={() => <TopNavigationAction icon={SettingsIcon} onPress={toggleMenu} />}
      visible={menuVisible}
      onBackdropPress={toggleMenu}
    >
      <MenuItem accessoryLeft={LogoutIcon} title='Logout' onPress={() => authContext.signOut()} />
    </OverflowMenu>
  );

  const handleNewGamePressed = () => {
    navigation.push('CreateGameScreen');
  };

  return (
    <ThemedSafeAreaView style={styles.container}>
      <TopNavigation title='Pass the Cup' alignment='center' accessoryRight={Settings} />
      <Divider />
      <Layout style={styles.layout}>
        {gamesReq.state.loading && (
          <FullSpinner />
        )}
        {gamesReq.state.error && (
          <View style={styles.errorContainer}>
            <Text>{gamesReq.state.error.message}</Text>
          </View>
        )}
        {!gamesReq.state.loading && !gamesReq.state.error && (
          <>
            <SectionList
              sections={sections}
              renderItem={({ item }) => renderGame(item)}
              renderSectionHeader={({ section }) => renderSectionHeader(section.title)}
              ItemSeparatorComponent={Divider}
              renderSectionFooter={() => <View style={styles.spacer} />}
            />
          </>
        )}
        <CallToAction action='New Game' onPress={handleNewGamePressed} />
      </Layout>
    </ThemedSafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  layout: {
    flex: 1,
  },
  errorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  sectionHeader: {
    paddingHorizontal: Styles.spacing.horizontal,
    paddingVertical: Styles.spacing.vertical,
  },
  spacer: {
    marginBottom: spacing(4),
  },
});

export default HomeScreen;
