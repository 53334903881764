/* eslint-disable global-require */
import React from 'react';
import { FlatList, StyleSheet, View, Image } from 'react-native';
import { ListItem, Text, Button } from '@ui-kitten/components';
import dateFormat from 'dateformat';
import { useTheme } from '@react-navigation/native';
import { useActionSheet } from '@expo/react-native-action-sheet';
import Styles, { spacing } from '../utils/styling';
import { Game } from '../api/Api';

type GameProps = {
  game: Game;
  loading: boolean;
  onGameAction: (action: string) => void;
}

const TheGame = ({ game, loading, onGameAction }: GameProps) => {
  const theme = useTheme();
  const { showActionSheetWithOptions } = useActionSheet();
  const actions = ['Freekick', 'Cornerkick', 'Goalkick', 'Yellowcard', 'Redcard', 'Undo', 'End', 'Cancel'];

  const handleActionPressed = () => {
    showActionSheetWithOptions({
      options: actions,
      cancelButtonIndex: 7,
      destructiveButtonIndex: 6,
    }, (buttonIndex) => {
      if (buttonIndex < 7) {
        onGameAction(actions[buttonIndex]);
      }
    });
  };
  return (
    <View style={styles.container}>
      <Text category='h5' style={styles.title}>- {game.name ? game.name : 'Game'} -</Text>
      <View style={styles.playersContainer}>
        <Text category='h6' style={styles.header}>Players:</Text>
        <FlatList
          data={game.players}
          keyExtractor={item => item.id}
          renderItem={({ item: player }) => {
            const current = game.currentCupHolder === player.order;
            const out = player.isEliminated;
            return (
              <ListItem
                title={() => (
                  <Text
                    style={{
                      opacity: out ? 0.5 : 1.0,
                      fontWeight: current ? 'bold' : 'normal',
                      marginStart: Styles.spacing.horizontal
                    }}
                  >
                    {player.name}
                  </Text>
                )}
                accessoryLeft={() => {
                  if (game.currentCupHolder === player.order) {
                    return (
                      <Image
                        style={styles.cupImage}
                        resizeMode='contain'
                        source={game.isReversed ? require('../assets/cupholder-up.png') : require('../assets/cupholder-down.png')}
                      />
                    );
                  }
                  return (
                    <View style={styles.cupImage} />
                  );
                }}
              />
            );
          }}
        />
      </View>
      <View style={styles.eventsContainer}>
        <Text category='h6' style={styles.header}>Events:</Text>
        <FlatList
          data={game?.events ?? []}
          keyExtractor={item => item.timestamp.toString()}
          renderItem={({ item: event }) => {
            return (
              <ListItem
                title={`${dateFormat(event.timestamp, 'hh:mm:ss')}\n${event.description}`}
              />
            );
          }}
        />
      </View>

      <View style={[styles.actionContainer, { borderTopColor: theme.colors.border }]}>
        <Button onPress={handleActionPressed} disabled={loading}>
          Action!
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    marginHorizontal: Styles.spacing.horizontal,
  },
  playersContainer: {
    maxHeight: '40%',
  },
  eventsContainer: {
    flex: 1,
    marginVertical: Styles.spacing.vertical,
  },
  title: {
    alignSelf: 'center',
    marginVertical: Styles.spacing.vertical,
  },
  cupImage: {
    width: 33,
    height: 33,
  },
  actionContainer: {
    padding: spacing(2),
    borderTopWidth: 1,
  },
});

export default TheGame;
