import { useState } from 'react';
import B2CClient from '../auth/b2cClient';
import { b2cConfig, b2cScopes as scopes } from '../auth/msalConfig';

const b2cClient = new B2CClient(b2cConfig);

export const useAuth = () => {
  const [accessToken, setAccessToken] = useState<string>();

  const signIn = async () => {
    try {
      const res = await b2cClient.signIn({
        scopes,
        webviewParameters: {
          // experiment with an ephemeral session
          ios_prefersEphemeralWebBrowserSession: false,
        },
      });
      setAccessToken(res.accessToken);
    } catch (error) {
      console.warn(error);
    }
  };

  const signOut = async () => {
    try {
      await b2cClient.signOut();
      setAccessToken(undefined);
    } catch (error) {
      console.warn(error);
    }
  };

  const checkSession = async () => {
    await b2cClient.init();
    const isSignedIn = await b2cClient.isSignedIn();
    if (isSignedIn) {
      try {
        const token = (await b2cClient.acquireTokenSilent({ scopes })).accessToken;
        setAccessToken(token);
        return token;
      } catch (e) {
        // something bad happened when trying to acquire a token, lets just have the user try and sign in.
        await signOut();
        console.warn(e);
      }
    }
    return '';
  };

  return {
    signIn,
    signOut,
    checkSession,
    accessToken,
  };
};
