import { NavigationContainer, RouteProp } from '@react-navigation/native';
import { createStackNavigator, StackNavigationProp } from '@react-navigation/stack';
import React, { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import CreateGameScreen from '../screens/CreateGameScreen';
import GameScreen from '../screens/GameScreen';
import HomeScreen from '../screens/HomeScreen';
import SignInScreen from '../screens/SignInScreen';

export const MainStackScreens = {
  HomeScreen: 'HomeScreen',
  GameScreen: 'GameScreen',
  CreateGameScreen: 'CreateGameScreen',
} as const;

export const SignInStackScreens = {
  SignInScreen: 'SignInScreen',
} as const;

export type RootStackParamList = {
  HomeScreen: undefined;
  GameScreen: { gameId: string };
  CreateGameScreen: undefined;
};

export type ScreenProps<Screen extends keyof typeof MainStackScreens> = {
  route: RouteProp<RootStackParamList, Screen>;
  navigation: StackNavigationProp<RootStackParamList, Screen>;
}

const RootStack = createStackNavigator<RootStackParamList>();
const SignInStack = createStackNavigator<{
  SignInScreen: undefined;
}>();

const Navigation = () => {
  const authContext = useContext(AuthContext);

  return (
    <NavigationContainer
      linking={{
        prefixes: ['https://www.igotthecup.com', 'igotthecup://'],
        config: {
          screens: {
            GameScreen: 'play/:gameId',
          },
        },
      }}
    >
      {authContext.isSignedIn
        ? (
          <RootStack.Navigator
            initialRouteName='HomeScreen'
            headerMode='none'
          >
            <RootStack.Screen
              name='HomeScreen'
              component={HomeScreen}
            />
            <RootStack.Screen
              name='GameScreen'
              component={GameScreen}
              initialParams={{ gameId: '1' }}
            />
            <RootStack.Screen
              name='CreateGameScreen'
              component={CreateGameScreen}
            />
          </RootStack.Navigator>
        )
        : (
          <SignInStack.Navigator mode='modal' headerMode='none'>
            <SignInStack.Screen
              name='SignInScreen'
              component={SignInScreen}
              options={{
                headerShown: false,
                animationTypeForReplace: 'pop',
              }}
            />
          </SignInStack.Navigator>
        )}
    </NavigationContainer>
  );
};

export default Navigation;
