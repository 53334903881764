import { Icon, IconProps, TopNavigationAction, TopNavigationActionProps } from '@ui-kitten/components';
import React from 'react';
import { ImageProps } from 'react-native';

const BackIcon = (props?: Partial<ImageProps>) => (
  <Icon {...props} name='arrow-back' />
);

const BackAction = ({ onPress }: Pick<TopNavigationActionProps, 'onPress'>) => (
  <TopNavigationAction icon={BackIcon} onPress={onPress} />
);

export default BackAction;
