import { Divider, Input, Layout, TopNavigation, Text, Select, SelectItem, useTheme } from '@ui-kitten/components';
import dateFormat from 'dateformat';
import { isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View, StyleSheet, Platform } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import useApiRequest from '../api/Api';
import BackAction from '../components/BackAction';
import CallToAction from '../components/CallToAction';
import { ScreenProps } from '../components/Navigation';
import ThemedSafeAreaView from '../components/ThemedSafeAreaView';
import Styles, { spacing } from '../utils/styling';

type CreateGameScreenProps = ScreenProps<'CreateGameScreen'>;

type FormData = {
  name: string;
  pointBuyIn: number;
  scheduledStartTime: string;
};

const CreateGameScreen = ({
  navigation,
}: CreateGameScreenProps) => {
  const theme = useTheme();
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const { control, register, handleSubmit, errors } = useForm<FormData>();
  const createGameReq = useApiRequest({
    method: 'POST',
    path: 'api/game',
  });

  useEffect(() => {
    if (createGameReq.state.data) {
      navigation.pop();
    }
  }, [createGameReq.state, navigation]);

  const handleBackPressed = () => {
    navigation.pop();
  };
  const points = [5, 10, 15, 20, 25];

  const onSubmit = async (data: FormData) => {
    await createGameReq.makeRequest(data);
  };

  return (
    <ThemedSafeAreaView style={styles.container}>
      <TopNavigation title='New Game' alignment='center' accessoryLeft={() => <BackAction onPress={handleBackPressed} />} />
      <Divider />
      <Layout style={styles.layout}>
        <View style={styles.form}>
          <Controller
            control={control}
            name='name'
            rules={{ required: true }}
            defaultValue=''
            render={({ onChange, onBlur, value }) => (
              <View style={styles.control}>
                <Input
                  placeholder='e.g. Griffindor vs. Slytherin'
                  onBlur={onBlur}
                  onChangeText={value => onChange(value)}
                  value={value}
                  keyboardType='default'
                  autoCorrect={false}
                  label={() => <Text category='label' style={styles.label}>The name of the game</Text>}
                />
                {errors.name && <Text category='label' status='danger'>A name for the game is required</Text>}
              </View>

            )}
          />
          <Controller
            control={control}
            name='pointBuyIn'
            rules={{ required: true }}
            defaultValue={points[0]}
            render={({ onChange, onBlur, value }) => (
              <View style={styles.control}>
                <Select
                  ref={register}
                  style={styles.control}
                  label={() => <Text category='label' style={styles.label}>Point Buy In</Text>}
                  value={value}
                  onBlur={onBlur}
                  onSelect={(v) => {
                    const indexPath = isArray(v) ? v[0] : v;
                    onChange(points[indexPath.row]);
                  }}
                >
                  {points.map(p => <SelectItem title={p.toString()} />)}
                </Select>
                {errors.pointBuyIn && <Text category='label' status='danger'>Point Buy In is required</Text>}
              </View>
            )}
          />
          <Controller
            control={control}
            name='scheduledStartTime'
            rules={{ required: true }}
            defaultValue={new Date()}
            render={({ onChange, onBlur, value }) => (
              <View style={styles.control}>
                {Platform.OS === 'web'
                  ? (
                    <></>
                  )
                  : (
                    <>
                      <TouchableOpacity onPress={() => setIsDatePickerVisible(true)}>
                        <Input
                          value={dateFormat(value)}
                          disabled
                          textStyle={{ color: theme['text-basic-color'] }}
                          label={() => <Text category='label' style={styles.label}>Scheduled start time</Text>}
                        />
                      </TouchableOpacity>
                      <DateTimePickerModal
                        isVisible={isDatePickerVisible}
                        mode='datetime'
                        onConfirm={(date) => {
                          setIsDatePickerVisible(false);
                          onChange(date.toISOString());
                        }}
                        onCancel={(date) => {
                          setIsDatePickerVisible(false);
                          onChange(date.toISOString());
                        }}
                      />
                    </>
                  )}
                {errors.scheduledStartTime && <Text category='label' status='danger'>A date and time are required</Text>}
              </View>
            )}
          />
        </View>
        <CallToAction action='Create Game' onPress={handleSubmit(onSubmit)} disabled={createGameReq.state.loading} />
      </Layout>
    </ThemedSafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  layout: {
    flex: 1,
    paddingTop: Styles.spacing.vertical * 2,
  },
  form: {
    paddingHorizontal: Styles.spacing.horizontal,
    marginVertical: Styles.spacing.vertical,
    flex: 1,
  },
  control: {
    marginBottom: spacing(4),
  },
  label: {
    marginBottom: spacing(2),
  },
});

export default CreateGameScreen;
