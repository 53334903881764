const spacingBase = 4;

export const spacing = (value: number) => value * spacingBase;

const Styles = {
  spacing: {
    horizontal: spacing(4),
    vertical: spacing(2),
  },
};

export default Styles;
