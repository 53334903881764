import { useReducer, useCallback, useContext } from 'react';
import axios, { AxiosRequestConfig, Method } from 'axios';
import AuthContext from '../context/AuthContext';

export type User = {
  id: string;
  email: string;
  name: string;
  venmoHandle: string;
  gamesPlayed: number;
  gamesWon: number;
  totalPossession: number;
  redCards: number;
};

export type GameEvent = {
  timestamp: number;
  cupHolder: number;
  isReversed: boolean;
  action: string;
  description: string;
}

export type Player = {
  id: string;
  name: string;
  order: number;
  pointsSpent: number;
  isJoined: boolean;
  isRegistered: boolean;
  isEliminated: boolean;
  possessionTime: number;
}

export type Game = {
  id: string;
  name: string;
  owner: string;
  isStarted: boolean;
  isComplete: boolean;
  pointBuyIn: number;
  pointTotal: number;
  currentCupHolder: number;
  isReversed: boolean;
  scheduledStartTime: number;
  startTime: number;
  endTime: number;
  maxPlayers: number;
  players: Player[];
  events: GameEvent[];
}

type Action<T> =
  | { type: 'fetching' }
  | { type: 'success', results: T }
  | { type: 'failure', error: Error }

type State<T> = {
  loading: boolean,
  error?: Error,
  data?: T,
}

const createReducer = <T>() => (state: State<T>, action: Action<T>): State<T> => {
  switch (action.type) {
    case 'failure':
      return { loading: false, error: action.error };
    case 'success':
      return { loading: false, data: action.results };
    case 'fetching':
      return { loading: true };
    default:
      return { loading: true };
  }
};

// Set config defaults when creating the instance
const instance = axios.create({
  baseURL: 'https://ptc-api.azurewebsites.net/',
});

// primary hook
export const useApiRequest = <ResponseType>(opts: {
  method: Method,
  path: string,
}) => {
  const { method, path } = opts;
  const [state, dispatch] = useReducer(createReducer<ResponseType>(), { loading: false });
  const { checkSession, signOut } = useContext(AuthContext);

  const makeRequest = useCallback(async (params?: Record<string, unknown>) => {
    dispatch({ type: 'fetching' });
    try {
      const token = await checkSession();
      let query;
      if (method === 'GET') {
        query = !params ? undefined : Object.entries(params).map(entry => `${entry[0]}=${encodeURIComponent(entry[1] as string)}`).join('&');
      }
      const url = query ? `${path}?${query}` : path;
      const config: AxiosRequestConfig = {
        method,
        url,
        headers: {
          Authorization: `Bearer ${token}`,
          ...instance.defaults.headers,
        },
        data: params ? JSON.stringify(params) : undefined,
      };
      const res = await instance.request<ResponseType>(config);
      dispatch({ type: 'success', results: res.data });
    } catch (error) {
      if (error.errorCode === 'interaction_required') {
        await signOut();
      }
      dispatch({ type: 'failure', error });
    }
  }, [checkSession, method, path, signOut]);

  return {
    state,
    makeRequest,
  };
};

export default useApiRequest;
