import { useTheme } from '@ui-kitten/components';
import React from 'react';
import { SafeAreaView, SafeAreaViewProps } from 'react-native-safe-area-context';

const ThemedSafeAreaView: React.FC<SafeAreaViewProps> = (props) => {
  const theme = useTheme();
  return (
    <SafeAreaView {...props} style={[props.style, { backgroundColor: theme['background-basic-color-1'] }]} />
  );
};

export default ThemedSafeAreaView;
