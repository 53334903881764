/* eslint-disable global-require */
import React, { useEffect, useState } from 'react';
import { StyleSheet, FlatList, View, Share, Alert, Platform } from 'react-native';
import Clipboard from 'expo-clipboard';
import { Button, Divider, Layout, ListItem, Text, TopNavigation } from '@ui-kitten/components';
import * as signalR from '@microsoft/signalr';
import axios from 'axios';
import dateFormat from 'dateformat';
import useApiRequest, { Game } from '../api/Api';
import { ScreenProps } from '../components/Navigation';
import Styles, { spacing } from '../utils/styling';
import { useEffectAsync } from '../hooks/useEffectAsync';
import { useAuth } from '../hooks/useAuth';
import ThemedSafeAreaView from '../components/ThemedSafeAreaView';
import BackAction from '../components/BackAction';
import FullSpinner from '../components/FullSpinner';
import TheGame from '../components/TheGame';

type GameScreenProps = ScreenProps<'GameScreen'>;

export const GameContext = React.createContext<Game | undefined>(undefined);

const GameScreen = ({
  route,
  navigation,
}: GameScreenProps) => {
  const gameId = route.params.gameId;
  const [game, setGame] = useState<Game>();
  const [loading, setLoading] = useState(false);
  const [connection, setConnection] = useState<signalR.HubConnection>();
  const { checkSession } = useAuth();

  const gamesReq = useApiRequest<Game>({ method: 'get', path: `api/game?id=${route.params.gameId}&action=connect` });

  useEffectAsync(async () => {
    await gamesReq.makeRequest();
  }, [gameId]);

  useEffect(() => {
    setGame(gamesReq.state.data);
  }, [gamesReq.state.data]);

  const joinSignalr = async (id: string, connectionId: string) => {
    if (!game) {
      return;
    }
    setLoading(true);
    const token = await checkSession();
    await axios.get(`https://ptc-api.azurewebsites.net/api/joinGameGroup?gameId=${game.id}&connectionId=${connectionId}`, { headers: { Authorization: `Bearer ${token}` } });
    setLoading(false);
  };

  const performAction = async (action: string) => {
    if (!game) {
      return;
    }
    setLoading(true);
    const url = `https://ptc-api.azurewebsites.net/api/game?id=${game.id}&action=${action}`;
    const token = await checkSession();
    await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });
    setLoading(false);
  };

  useEffectAsync(async () => {
    const asyncCreateConnection = async () => {
      const c = new signalR.HubConnectionBuilder().withUrl('https://ptc-api.azurewebsites.net/api').build();
      // main update trigger
      c.on('gameUpdate', (message) => {
        setGame(JSON.parse(message));
      });
      await c.start();
      if (c.connectionId !== null) {
        await joinSignalr(gameId, c.connectionId);
      }
      setConnection(c);
    };

    // only connect socket if not currently connected and game is not complete
    if (game && !game.isComplete && (!connection ?? !connection?.connectionId)) {
      await asyncCreateConnection();
    }
  }, [gameId, game, connection]);

  const handleKickOffPressed = async (game: Game) => {
    try {
      await performAction('start');
    } catch (e) {
      Alert.alert('An error occured.', 'Something went wrong when trying kicking off.');
      console.error(e);
    }
  };

  const handleBackPressed = () => {
    navigation.pop();
  };

  const handleInvite = async () => {
    try {
      const url = `http://www.igotthecup.com/play/${gameId}`;
      if (Platform.OS === 'web') {
        console.log('test');
        Clipboard.setString(url);
        alert('Invite link was copied to the clipboard.');
      } else {
        await Share.share({ message: url });
      }
    } catch (e) {
      Alert.alert('An error occured.', 'Something went wrong when trying to send the link.');
      console.error(e);
    }
  };

  // eslint-disable-next-line no-nested-ternary
  let title = '';
  if (game) {
    if (!game.isStarted) {
      title = 'Pre-Game';
    } else if (game.isComplete) {
      title = 'Final';
    } else {
      title = 'Play';
    }
  }

  const PreGame = game && (
    <>
      <>
        <Text category='h5'>Scheduled Start</Text>
        <Text>{dateFormat(game.scheduledStartTime, '')}</Text>
        <Button status='primary' appearance='ghost' onPress={() => handleKickOffPressed(game)}>Kick off now!</Button>
      </>
      <FlatList
        data={game.players}
        renderItem={({ item: player }) => (
          <ListItem
            key={player.id}
            title={player.name}
          />
        )}
        ListHeaderComponent={() => <Text category='h4'>Players</Text>}
      />
    </>
  );

  const Final = (
    <View style={{ marginTop: spacing(3) }}>
      <Text category='h6' style={styles.title}>Players:</Text>
      <FlatList
        data={game?.players ?? []}
        renderItem={({ item: player }) => (
          <ListItem
            key={player.id}
            title={`${player.name} ${(game?.currentCupHolder === player.order) ? ' - took the cup!' : ''}`}
          />
        )}
      />
    </View>

  );

  const Game = game && <TheGame game={game} onGameAction={performAction} loading={loading} />;

  const Loading = <FullSpinner />;

  const MainContent = (() => {
    switch (title) {
      case 'Final':
        return Final;
      case 'Play':
        return Game;
      case 'Pre-Game':
        return PreGame;
      default:
        return Loading;
    }
  })();

  return (
    <ThemedSafeAreaView style={styles.container}>
      <TopNavigation title={title} alignment='center' accessoryLeft={() => <BackAction onPress={handleBackPressed} />} />
      <Divider />
      <View style={styles.invite}>
        <Button onPress={handleInvite}>Invite</Button>
      </View>
      <Layout style={styles.layout}>
        {game ? MainContent : Loading}
      </Layout>
    </ThemedSafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  layout: {
    flex: 1,
  },
  invite: {
    margin: Styles.spacing.horizontal,
  },
  gameCardContainer: {
    marginHorizontal: Styles.spacing.horizontal,
  },
  title: {
    alignSelf: 'center',
  },
});

export default GameScreen;
