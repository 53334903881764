/* eslint-disable no-console */
import 'react-native-gesture-handler';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { mapping, light, dark } from '@eva-design/eva';
import { ApplicationProvider, IconRegistry } from '@ui-kitten/components';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { EvaIconsPack } from '@ui-kitten/eva-icons';
import { ActionSheetProvider, connectActionSheet } from '@expo/react-native-action-sheet';
import { Notifications } from 'react-native-notifications';
import { Alert, Platform } from 'react-native';
import axios from 'axios';
import { useAuth } from './hooks/useAuth';
import { useEffectAsync } from './hooks/useEffectAsync';
import AuthContext from './context/AuthContext';
import Navigation from './components/Navigation';
import theme from './theme.json';

function App() {
  const { signIn, signOut, checkSession, accessToken } = useAuth();
  const [deviceToken, setDeviceToken] = useState<string>();

  useEffectAsync(async () => {
    await checkSession();
  }, []);

  useEffect(() => {
    if (accessToken) {
      Notifications.registerRemoteNotifications();
      Notifications.events().registerRemoteNotificationsRegistered((event) => {
        console.log('Device Token Received', event.deviceToken);
        setDeviceToken(event.deviceToken);
      });
      Notifications.events().registerRemoteNotificationsRegistrationFailed((event) => {
        console.error(event);
      });
      Notifications.events().registerNotificationReceivedForeground((notification, completion) => {
        console.log(`Notification received in foreground: ${notification.title} : ${notification.body}`);
        console.log({ notification });
        Alert.alert(notification.body);
        completion({ alert: false, sound: false, badge: false });
      });
      Notifications.events().registerNotificationOpened((notification, completion) => {
        console.log(`Notification opened: ${notification.payload}`);
        completion();
      });
    }
  }, [accessToken]);

  useEffectAsync(async () => {
    if (!deviceToken || !accessToken) {
      return;
    }
    const platform = Platform.OS === 'android' ? 'fcm' : 'apns';
    const url = `https://ptc-api.azurewebsites.net/api/registerDevice?token=${deviceToken}&platform=${platform}`;
    const res = await axios.get(url, { headers: { Authorization: `Bearer ${accessToken}` } });
    console.log(`registerDevice result: ${JSON.stringify(res.status)}`);
  }, [deviceToken, accessToken]);

  return (
    <>
      <IconRegistry icons={EvaIconsPack} />
      <ApplicationProvider mapping={mapping} theme={{ ...dark, ...theme }}>
        <SafeAreaProvider>
          <AuthContext.Provider value={{
            checkSession,
            signIn,
            signOut,
            token: accessToken,
            isSignedIn: !_.isEmpty(accessToken),
          }}
          >
            <ActionSheetProvider>
              <Navigation />
            </ActionSheetProvider>
          </AuthContext.Provider>
        </SafeAreaProvider>

      </ApplicationProvider>
    </>
  );
}

const ConnectedApp = connectActionSheet(App);

export default ConnectedApp;
