import React from 'react';
import { Button } from '@ui-kitten/components';
import { View, StyleSheet } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { spacing } from '../utils/styling';

type CallToActionProps = {
  action: string;
  onPress: () => void;
  disabled?: boolean;
}

const CallToAction = ({
  action,
  onPress,
  disabled = false,
}: CallToActionProps) => {
  const theme = useTheme();
  return (
    <View style={[styles.buttonContainer, { borderTopColor: theme.colors.border }]}>
      <Button onPress={onPress} disabled={disabled}>
        {action}
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    padding: spacing(2),
    borderTopWidth: 1,
  },
});

export default CallToAction;
