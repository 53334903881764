import { Spinner, SpinnerProps } from '@ui-kitten/components';
import React from 'react';
import { StyleSheet, View } from 'react-native';

const FullSpinner = (props: SpinnerProps) => (
  <View style={styles.spinner}>
    <Spinner {...props} />
  </View>
);

const styles = StyleSheet.create({
  spinner: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default FullSpinner;
