import { Platform } from 'react-native';
import type { B2CConfiguration } from './b2cClient';

export const b2cConfig: B2CConfiguration = {
  auth: {
    clientId: 'd5c93acf-23a7-4f1d-95b1-8fcc3ee8a5d7',
    authorityBase: 'https://passthecup.b2clogin.com/tfp/passthecup.onmicrosoft.com',
    policies: {
      signInSignUp: 'B2C_1_igtc_sign_in_up_flow',
    },
    redirectUri: Platform.select({
      default: 'https://www.igotthecup.com',
      // web: 'http://localhost:19006',
      ios: undefined,
      android: undefined,
    }),
  },
  // web only:
  cache: { cacheLocation: 'localStorage' },
};

export const b2cScopes = ['https://passthecup.onmicrosoft.com/api/access'];
