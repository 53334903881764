import { createContext } from 'react';

type AuthContextType = {
  signIn: () => Promise<void>;
  signOut: () => Promise<void>;
  checkSession: () => Promise<string>;
  isSignedIn: boolean;
  token?: string;
};

const AuthContext = createContext<AuthContextType>({
  checkSession: () => Promise.resolve(''),
  signIn: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  isSignedIn: false,
});

export default AuthContext;
